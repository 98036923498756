import React, { useState, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Fade from "react-reveal/Fade"
import CrossfadeImage from "react-crossfade-image"
import BlockCta from "src/components/Blocks/BlockCta"
import BlockConfigurations from "src/components/Blocks/BlockConfigurations"
import Breadcrumbs from "src/utils/Breadcrumbs"

const ArchiveListing = props => {
  return (
    <section className="container pt-12 pb-0 lg:pb-12 internal-door-styles-items">
      <div className="flex flex-col lg:flex-row flex-wrap justify-center">
        {props.data.map((item, index) => {
          return <ArchiveListingItem data={item} key={index} />
        })}
      </div>
    </section>
  )
}

const ArchiveListingItem = props => {
  var image = ""

  if (props.selected === "All") {
    image = props.data.images[0]
  } else {
    image = props.data.images.find(image => image.selected === true)
  }

  if (!image || (image && image.src === null)) {
    return null
  }

  return (
    image && (
      <Link
        to={`/garage-doors/styles/${props.data.uid}/`}
        className="group relative w-full lg:w-6/12 p-2 text-left mb-0"
        title={`${props.data.title}`}
      >
        <Fade>
          <div className="w-full relative bg-white">
            <CrossfadeImage
              src={image ? image.src : ""}
              alt={props.data.title}
              containerClass={`absolute top-0 left-0 w-full flex justify-center items-end transition duration-500 ease-in-out h-full`}
            />
            {props.data.featured_image && (
              <img
                src={props.data.featured_image}
                alt={props.data.title}
                className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-1000 ease-in-out"
              />
            )}
          </div>
        </Fade>
        <Fade>
          <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase my-4 group-hover:text-gold duration-500 ease-in-out">
            {props.data.title}
          </h2>
        </Fade>
      </Link>
    )
  )
}

const GarageDoorsStyles = props => {
  const defaultWood = "All"

  /* eslint-disable no-unused-vars */
  const [selected, setSelected] = useState("All")
  /* eslint-enable no-unused-vars */

  const doorData = props.data.allPrismicGarageDoor.edges.map((item, index) => {
    const images = item.node.data.woods_and_finishes.map((item2, index2) => {
      return {
        uid: item2.wood_or_finish.uid,
        name: item2.wood_or_finish.document
          ? item2.wood_or_finish.document.data.title.text
          : defaultWood,
        src: item2.door_image.url,
        selected:
          selected === item2.wood_or_finish.document
            ? item2.wood_or_finish.document.data.title.text
            : defaultWood
            ? true
            : false,
      }
    })
    return {
      uid: item.node.uid,
      title: item.node.data.title.text,
      images: images,
      featured_image: item.node.data.featured_image.url,
    }
  })

  /* eslint-disable no-unused-vars */
  const [doorState, setDoorState] = useState(doorData)
  /* eslint-enable no-unused-vars */

  // Get the height of the first section so we can pass it down
  // to the trigger for the bottom strip

  /* eslint-disable no-unused-vars */
  const [position, setPosition] = useState(0)
  /* eslint-enable no-unused-vars */

  const stipRef = useRef()

  useEffect(() => {
    const updateHeight = () => {
      setPosition(stipRef.current.clientHeight)
    }

    updateHeight()

    window.addEventListener("resize", updateHeight)
  })

  const crumbs = [
    { label: "Garage Doors", href: "/garage-doors" },
    { label: "Door Styles", href: "/garage-doors/styles", active: true },
  ]

  return (
    <main className="internal-door-style">
      <Seo title={"Garage Door Styles | Garage Door Designs"}
        description={
          "We craft beautifully modern garage doors that utilise the most innovative features. Complement contemporary exterior design with made-to-measure garage doors"
        } />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <div ref={stipRef}>
        <section className="container pb-0">
          <div className="flex flex-row">
            <div className="mx-auto text-center">
              <Fade>
                <h2 className="font-display text-gold rfs:text-6xl">
                  Garage Door Styles
                </h2>
                <div className="w-12 separator mt-5"></div>
                <p className="max-w-xl m-auto mt-5 text-sm">
                  We can craft beautifully modern garage doors that utilise the
                  most innovative and unique features. Complement contemporary
                  exterior design with striking <Link to="/garage-doors/wooden-made-to-measure-garage-doors/">made to measure</Link> garage doors
                  that provide a space-saving solution or simply act as a focal
                  point.
                </p>
                <p className="max-w-xl m-auto mt-3 text-sm">
                  Our garage doors are available in a range of{" "}
                  <Link to="/garage-doors/woods-finishes/">
                    woods and finishes
                  </Link>
                  , explore our range below and{" "}
                  <Link to="/contact/">get in touch</Link> if you have any
                  questions.
                </p>
              </Fade>
            </div>
          </div>
        </section>
        <ArchiveListing data={doorState} />
      </div>
      <BlockConfigurations props={`garage-doors`} />
      {/* <WoodsAndFinishesSelector
        data={props.data.allPrismicWoodsAndFinishes.edges}
        position={position}
        selected={selected}
        state={doorState}
        updateSelection={updateSelection}
      /> */}
      <BlockCta />
    </main>
  )
}

export default GarageDoorsStyles

export const GarageDoorsStylesQuery = graphql`
  query GarageDoorsStylesQuery {
    allPrismicGarageDoor(sort: { fields: data___custom_order, order: DESC }) {
      edges {
        node {
          data {
            title {
              text
            }
            woods_and_finishes {
              wood_or_finish {
                uid
                document {
                  ... on PrismicWoodsAndFinishes {
                    data {
                      title {
                        text
                      }
                      featured_image {
                        url
                      }
                    }
                  }
                }
              }
              door_image {
                url
              }
            }
            featured_image {
              url
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            door_type
            listing_image {
              url
              alt
            }
          }
          uid
        }
      }
    }
    allPrismicWoodsAndFinishes(
      filter: { data: { door_type: { eq: "Garage Door" } } }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            featured_image {
              url(imgixParams: { w: 60, h: 60 })
            }
          }
          uid
        }
      }
    }
  }
`
